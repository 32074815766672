@import "../../assets/theme/vars";

.wrapper_menu_aside {
  background: var(--primary_color);
  position: fixed;
  z-index: 99;
  top: 65px;
  left: 0;
  width: 215px;
  height: calc(100% - 65px);
  overflow: auto;
  color: $white_text;
  padding: 1.25rem 0.25rem 0.5rem 0.5rem;
  -webkit-transition: 0.25s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  transition: 0.25s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
  }

  &.is-show {
    margin-left: 0;
  }
  &::after {
    content: "";
    -webkit-transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
    transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 215px;
    height: 40%;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(0, 0, 0, 0)),
      color-stop(100%, rgba(0, 0, 0, 0.25))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.25) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
  }

  .nav_menu_items {
    position: relative;
    z-index: 1;
    ul {
      padding-left: 0;
      list-style: none;
      hr {
        margin: 0.75rem 0;
        border-top: 2px solid rgba(255, 255, 255, 0.25);
      }
      .menu_item {
        height: 38px;
        margin-bottom: 0.3rem;
        width: 100%;
        font-family: "is_medium";
        padding: 0.6rem 0.75rem;
        border-radius: 7px;
        display: flex;
        justify-items: flex-start;
        align-items: center;
        color: $white_text;
        cursor: pointer;
        position: relative;
        line-height: 1;
        font-size: .95rem;
        &.active,
        &:hover {
          background: $white_text;
          color: $black;
          & .ico_imgs img {
            filter: invert(100%);
          }
          & span {
            .dots_notif {
              background: var(--primary_color);
              color: #fff;
              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                background: rgba(0, 0, 0, 0.25);
                z-index: -1;
              }
            }
          }
        }
        .ico_imgs {
          width: 20px;
          min-width: 20px;
          margin-right: 0.65rem;
          img {
            width: 100%;
          }
        }
        span {
          .dots_notif {
            background: #fff;
            color: #333;
            position: absolute;
            top: 12px;
            right: 8px;
            display: flex;
            height: 16px;
            width: 30px;
            justify-content: center;
            align-items: center;
            line-height: 1;
            border-radius: 10px;
            font-size: 0.75rem;
          }
        }
      }
      .ul_collapse {
        padding-left: 0.85rem;
        li {
          .submenu_item {
            padding: 0.5rem 0.5rem 0.5rem 0.75rem;
            display: block;
            margin-bottom: 0;
            color: $white_text;
            font-family: "is_book";
            border-radius: 7px;
            &:hover,
            &.active {
              background: rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
    }
  }
  .links_legals{
    .content_legals{
      text-align: left;
      a{
        cursor: pointer;
        color: #fff;
        font-size: .80rem;
      }
    }
  }
}
