@import "../../assets/theme/vars";

.wrapper_profile {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .content_profile {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    flex-direction: row;
    .box_main_info_profile {
      height: 100%;
      width: 100%;
      .box_aside_btns {
        position: relative;
      }
      .btn_ellipsis {
        background: #dddddd !important;
        width: 25px;
        height: 25px;
        display: none;
        justify-content: center;
        align-items: center;
        margin-left: 0.75rem;
        border-radius: 50%;
        background: $bg_grey;
        cursor: pointer;
        color: #000000;
        position: absolute;
        top: -55px;
        right: 0;
      }
      .box_user_info {
        .main_info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 2rem;
          .box_one {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            position: relative;
            .box_avatar_user {
              .avatar {
                width: 125px;
                min-width: 125px;
                height: 125px;
                margin-right: 2rem;
                border: 5px solid #ffffff;
              }
            }
            // input[type="file"] {
            //   display: none;
            // }
            // .icons {
            //   position: absolute;
            //   width: 33px;
            //   height: 33px;
            //   border-radius: 50%;
            //   box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.35);
            //   cursor: pointer;
            //   background: #ffffff;
            // }
            // .ico_save {
            //   width: 35px;
            //   height: 35px;
            //   color: #ffffff;
            //   bottom: -4px;
            //   left: 40px;
            //   display: flex;
            //   justify-content: center;
            //   align-items: center;
            //   background: #278b13;
            //   border: 0;
            //   outline: none;
            // }
            // .custom-file-upload {
            //   display: inline-block;
            //   padding: 8px;
            //   bottom: -10px;
            //   left: 80px;
            //   &:hover {
            //     background: var(--primary_color);
            //     color: #ffffff;
            //   }
            // }
          }
          .box_two {
            width: 50%;
            text-align: center;
          }
        }
        .second_info {
          display: flex;
          justify-content: space-between;
          & > div {
            border-right: 1px solid #cccccc;
            border-bottom: 1px solid #cccccc;
            width: 100%;
            text-align: center;
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
      .box_details_account {
        margin-top: 2rem;
        margin-bottom: 2.25rem;
        position: relative;
        .text-absRight {
          position: absolute;
          right: 15px;
          top: 14px;
          font-size: 16px;
        }
      }
    }
  }
}

.item_badge {
  font-size: 1rem;
  font-family: "is_bold";
  font-size: 0.7rem;
  padding: 0.4rem 0.35rem;
  border-radius: 0.25rem;
  cursor: pointer;
  &.pending {
    background: $c_pending;
  }
  &.success {
    background: $c_success;
  }
  &.defeated {
    background: #cccccc;
    color: #777777;
  }
  &.rejected {
    background: #fa0000;
    color: #e6e6e6;
  }
}

.wrapper_pending {
  max-width: 270px;
  width: 100%;
  height: 100%;
  margin-left: 1.5rem;
  top: 80px;
  position: -webkit-sticky;
  position: sticky;
  .closeAll {
    cursor: pointer;
    position: absolute;
    top: 10px;
    font-size: 1.25rem;
    right: 15px;
    z-index: 99;
  }
  .content_peding {
    @include scrollbar_vertical;
    max-height: 250px;
    overflow: auto;
    .item_pending {
      padding: 0.75rem 1rem;
      border-bottom: 1px solid #efefef;
      display: block;
      &:hover {
        background: #efefef;
        .fnt_medium {
          color: var(--primary_color);
        }
      }
      &:last-child {
        border: 0;
      }
    }
  }
}

.wrapper_preferencias {
  .custom-control-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .span_drop {
      width: 28px;
      min-width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
}
