.wrapper_side_advertising {
  width: 85%;
  margin: 0 auto 1rem;
  position: relative;
  border-radius: 0.35rem;
  &:hover {
    .t_pub {
      color: var(--primary_color);
    }
    img {
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
    }
  }
  img {
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    // width: 80% !important;
    // margin: 0 auto;
  }
  .slick-dots {
    bottom: -10px;
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    background: rgb(255, 255, 255);
    position: absolute;
    top: 15px;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 9;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    box-shadow: 1px 1px 12px rgba(0,0,0,.5);
  }

  .slick-prev {
    right: 28px;
    border-radius: .25rem 0 0 0.25rem;
  }
  .slick-next {
    right: 5px;
    border-radius: 0 0.25rem .25rem 0;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    opacity: 1;
    outline: none;
    background: var(--primary_color);
  }
  .slick-prev:focus,
  .slick-prev:hover {
    &::before {
      border-right: 6px solid #ffffff;
    }
  }
  .slick-next:focus,
  .slick-next:hover {
    &::before {
      border-left: 6px solid #ffffff;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    content: "";
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 3px);
    z-index: 9;
  }

  .slick-prev:before {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-right: 6px solid #444444;
    border-bottom: 4px solid transparent;
  }

  .slick-next:before {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-left: 6px solid #444444;
    border-bottom: 4px solid transparent;
  }
}
