@import "../../assets/theme/vars";

@keyframes zoom-in__zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.box_ico_recompensas {
  position: absolute;
  //background: rgb(255, 255, 255);
  border-radius: 0.4rem 0.4rem;
  //box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    animation: zoom-in__zoom-out 1.1s ease infinite;
  }
}

.wrapper_directory {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .wrp_filter_directory {
    max-width: 250px;
    width: 100%;
    text-align: right;
  }
  .divtop {
    .ttls {
      .box_tl {
        width: 100%;
        display: flex;
        align-items: center;
        .box__tpride {
          background: #f2f2f2;
          position: relative;
          z-index: 1;
          padding: 0 1rem;
          font-size: 1.4rem;
        }
      }
      .box_options_admin {
        position: relative;
        z-index: 1;
        padding-left: 1rem;
        background: #f2f2f2;
        .linkbn {
          cursor: pointer;
          background-color: #ffc907;
          min-width: 145px;
          display: block;
          width: 100%;
          text-align: center;
          font-size: 0.95rem;
          font-family: "is_medium";
          padding: 0.5rem 0.25rem;
          border-radius: 0.25rem;
        }
      }
    }
  }
  .form-control-sm {
    border: 1px solid #ced4da;
    &:focus-visible {
      border: 1px solid var(--primary_color);
      outline: 1px solid var(--primary_color);
    }
  }
  .box_ttl_opt {
    .wrp_filter_directory {
      max-width: 100%;
    }
  }

  .card-text {
    font-size: 0.9rem;
  }
  .alinks:hover {
    color: var(--primary_color);
    cursor: pointer;
  }

  .item__directory {
    .card {
      position: relative;
      .box_ico_recompensas {
        top: 12px;
        right: 12px;
        width: 38px;
        height: 38px;
        img {
          height: 25px;
        }
      }
    }
  }
}

.box_filter_messg {
  background: cadetblue;
  margin-bottom: 2rem;
  gap: 10px;
  .box_ordering {
    width: 100%;
    margin: 0 !important;
  }
  .btn_clean_filter {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.15rem 0.5rem;
    border: 2px solid var(--primary_color);
    border-radius: 0.35rem;
    width: 55px;
    line-height: 1.1;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 600;
    &:hover {
      background: var(--primary_color);
      color: #fff;
    }
  }
  .form-control-sm {
    border: 1px solid #ced4da;
    width: 100%;
  }
}

.c_yellow {
  color: #ffc907;
}

.ttls_direct {
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .itemcat {
    background: var(--primary_color);
    color: #efefef;
    padding: 2px 6px;
    border-radius: 4px;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
  }
  .itemcat__btn {
    color: var(--primary_color);
    border: 1px solid var(--primary_color);
    padding: 4px 8px;
    border-radius: 4px;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
    &:hover {
      background: var(--primary_color);
      color: #efefef;
    }
  }
}

.h_line {
  font-size: 0.8rem;
  color: #bababa;
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  span {
    position: relative;
    z-index: 1;
    padding: 0 1rem;
    background: #fff;
  }
  &::after {
    content: "";
    height: 1px;
    width: 100%;
    background: #dedede;
    position: absolute;
    top: 7px;
    left: 0;
  }
}

.name__categ {
  min-height: 50px;
}

.wrapper_details_directory {
  max-width: 720px;
  padding: 2rem 1rem;
  margin: 0 auto;
  .box_toolbar_back {
    margin-bottom: 1.25rem;
    line-height: 1;
    .go-back {
      cursor: pointer;
      background: #efefef;
      font-size: 0.9rem;
      padding: 0.45rem 0.75rem;
      border-radius: 0.5rem;
      &:hover {
        background: var(--primary_color);
        color: #fff;
      }
    }
  }
  .card {
    // display: flex;
    .drawer_content {
      .content_navbar_opt{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        padding-bottom: .5rem;
        margin-bottom: .85rem;
        border-bottom: 1px solid #ccc;
        .form-check-label{
          font-size: .90rem;
        }
        .itemcat__btn{
          margin: 0;
        }
      }
      .conten_info_i {
        display: flex;
        gap: 20px;
        .box__ttls__details {
          width: 100%;
          .ttls_direct{
            align-items: flex-start;
          }
        }
        .box__pic__details {
          width: 100%;
          max-width: 250px;
          .sec__pict__details {
            position: relative;
            .box_ico_recompensas {
              top: 10px;
              right: 10px;
              width: 38px;
              height: 38px;
              img {
                height: 25px;
              }
            }
            img {
              max-height: 400px;
              border-radius: 1rem;
            }
          }
        }
      }
      .conten_info_ii{
        display: flex;
        margin-top: 1.5rem;
        gap: 20px;
        .box_data{
          width: 100%;
        }
        .data_box_detail{
          width: 100%;
          max-width: 250px;
          font-size: .9rem;
          line-height: 1.3;
        }
      }
    }
    .wrapper_ranking {
      padding: 0.75rem 1rem;
      display: flex;
      gap: 50px;
      .content_eval_ranking,
      .content_tooltip_ranking {
        width: 100%;
      }
      .set {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .ttls_direct {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
        .itemcat {
          margin: 0 4px !important;
        }
      }

      .drawer_sets {
        width: 100%;
      }
      .ttls_direct {
        display: block;
        width: 100%;
      }
    }
  }
}

.item_data_details {
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 8px;
  margin-bottom: 0.5rem;
  .ico_box_detail {
    background: #efefef;
    width: 26px;
    min-width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    border-radius: 50%;
    font-size: 0.9rem;
  }
  a {
    word-break: break-all;
  }
  .alinks:hover {
    color: var(--primary_color);
  }
}

@media screen and (max-width: 1200px) {
  .name__categ {
    min-height: 60px;
  }
}

@media screen and (max-width: 1090px) {
  .wrapper_directory {
    .box_filter_messg {
      flex-wrap: wrap;
      .box_ordering {
        width: 45%;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .wrapper_directory {
    .box_filter_messg {
      .box_ordering {
        width: 100% !important;
      }
    }
  }
}
