.content_user_activity {
  .nav.nav-tabs {
    margin-bottom: 0 !important;
    .nav-item {
      color: #666;
      &.active {
        color: #333;
        font-family: "is_medium";
      }
    }
  }
  .tab-content {
    border: 1px solid;
    border-color: #ffffff #dee2e6 #dee2e6 #dee2e6;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 0.5rem 0.5rem;
    background: #ffffff;
  }
  .content_history_activity,
  .content_services_profile {
    padding: 2rem 1.5rem;
  }
  .table{
    font-size: .85rem;
  }
}
