@import "react-big-calendar/lib/sass/styles";
@import "../../assets/theme/vars";

.event-event {
	background-color: #6b9acf !important;
}
.event-birthday {
	background-color: #8cbd6b !important;
}

.agenda-event {
	color: #6b9acf !important;
}
.agenda-birthday {
	color: #8cbd6b !important;
}

.wrapper_diary {
	.content_ttl_diary {
		margin-bottom: 1.5rem;
	}
}

.rbc-agenda-event-cell a {
	text-decoration: underline;
}


