.successful_reg {
  .box_brand_register {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
  .content_successful{
    text-align: center;
    margin-top: 1rem;
    .ico_ok{
      font-size: 4rem;
      color: var(--primary_color);
      line-height: 0;
    }
    h5{
      font-weight: 700;
      font-size: 1rem;
      letter-spacing: 4px;
      margin: .5rem 0 1rem;
    }
    h1{
      max-width: 500px;
      margin: 0 auto 2rem;
      line-height: 1.1;
    }
  }
}
