@import "../../assets/theme/vars";

.wrapper_embassador {
  @include bggrey;
  min-height: calc(100vh - 4rem);
  .wrp_filter_directory {
    max-width: 250px;
    width: 100%;
    text-align: right;
  }
  .divtop {
    .ttls {
      .box_tl {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .box_options_admin {
        position: relative;
        z-index: 1;
        padding-left: 1rem;
        background: #f2f2f2;
        .linkbn {
          cursor: pointer;
          background-color: #ffc907;
          min-width: 145px;
          display: block;
          width: 100%;
          text-align: center;
          font-size: 0.95rem;
          font-family: "is_medium";
          padding: 0.5rem 0.25rem;
          border-radius: 0.25rem;
        }
      }
    }
  }
  .form-control-sm {
    border: 1px solid #ced4da;
    &:focus-visible {
      border: 1px solid var(--primary_color);
      outline: 1px solid var(--primary_color);
    }
  }
  .box_ttl_opt {
    .wrp_filter_directory {
      max-width: 100%;
    }

.wrapper_ranking {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  border-top: 1px solid #dedede;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.wrapper_ranking .set {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.wrapper_ranking .ttls_direct {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.wrapper_ranking .ttls_direct .itemcat {
  margin: 0 4px !important;
}

  }

  .box_filter_messg {
    margin-bottom: 2rem;
    .box_ordering {
      width: 100%;
      .btn_clean_filter {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0.15rem 0.25rem;
        padding-left: 0.4rem;
        border: 2px solid var(--primary_color);
        border-radius: 0.35rem;
        &:hover {
          background: var(--primary_color);
          color: #fff;
          .fas {
            color: #fff;
          }
        }
        .fas {
          color: var(--primary_color);
        }
        .txt_clean_filter {
          width: 55px;
          padding-left: 0.4rem;
          line-height: 1.1;
          text-transform: uppercase;
          font-size: 0.7rem;
          font-weight: 600;
        }
      }
    }
    .form-control-sm {
      border: 1px solid #ced4da;
      width: 100%;
    }
  }
  .card-text {
    font-size: 0.9rem;
  }
  .alinks {
      word-break: break-all;
  }
  .alinks:hover {
    color: var(--primary_color);
    cursor: pointer;
  }

}

.ttls_direct {
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .itemcat {
    background: var(--primary_color);
    color: #efefef;
    padding: 2px 6px;
    border-radius: 4px;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
  }
}



@media screen and (max-width: 1090px) {
  .wrapper_directory {
    .box_filter_messg {
      flex-wrap: wrap;
      .box_ordering {
        width: 45%;
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 1090px) {
  .wrapper_directory {
    .box_filter_messg {
      flex-wrap: wrap;
      .box_ordering {
        width: 100%;
      }
    }
  }
}
