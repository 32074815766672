@import "../../assets/theme/vars";

.wrapper_head_rewards {
  .content_statistics {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1rem;
    .item_statistics {
      margin-right: 1rem;
      border-radius: $border_radius;
      padding: 0.75rem;
      &.item25 {
        width: 100%;
        height: 110px;
      }
      &:first-child {
        background: var(--primary_color);
        color: $white_text;
        position: relative;
        &::after {
          content: "";
          border-radius: $border_radius;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 60%;
          background: rgba(0, 0, 0, 0);
          background: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.25) 100%
          );
          background: -webkit-gradient(
            left top,
            left bottom,
            color-stop(0%, rgba(0, 0, 0, 0)),
            color-stop(100%, rgba(0, 0, 0, 0.25))
          );
          background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.25) 100%
          );
          background: -o-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.25) 100%
          );
          background: -ms-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.25) 100%
          );
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.25) 100%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
        }
      }
      &:nth-child(2) {
        background: #f1cfdc;
      }
      .statistics {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        text-align: center;
        height: 100%;
        * {
          margin: 0;
        }
        &.unique {
          flex-direction: row;
          text-align: left;
          justify-content: flex-start;
          padding-left: 0.25rem;
          .ico_unique {
            font-size: 2.5rem;
            background: $white_text;
            color: $black;
            min-width: 60px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.wrapper_category {
  .box_tag_category {
    width: 100%;
    max-width: 768px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    margin-bottom: 2.25rem;
    gap: 10px;
    .item_tab_category {
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      text-align: center;
      color: #bbbbbb;
      padding: 0.25rem;
      border-radius: 5px;
      &:last-child {
        display: none;
      }
      .tag_ico {
        font-size: 1.75rem;
        margin-bottom: 0.5rem;
      }
      .tag_name {
        font-family: "is_medium";
        text-transform: uppercase;
        font-size: 0.8rem;
        line-height: 1.1;
      }
      &:hover,
      &.active {
        color: var(--primary_color);
        background: #dedede;
      }
    }
  }
  .box_category_items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .box_item_cupon {
      margin-bottom: 1.5rem;
      width: 20%;
      padding: 0 1rem;
      &:hover {
        .info_cupon .ico_download {
          .fas {
            display: inline;
            color: var(--primary_color);
          }
          .far {
            display: none;
          }
        }
        .item_cupon {
          .box_img_cupon {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
      .item_cupon {
        .box_img_cupon {
          overflow: hidden;
          border-radius: $border_radius;
          img {
            border-radius: $border_radius;
          }
        }
      }
      .info_cupon {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0.5rem 0.5rem 0;
        .name_categ {
          color: var(--primary_color);
          font-size: 0.65rem;
          text-transform: uppercase;
        }
        .name_cupon {
          font-family: "is_medium";
        }
        .ico_download {
          font-size: 1.85rem;
          border-left: 1px solid #ccc;
          padding-left: 0.5rem;
          color: #aaaaaa;
          cursor: pointer;
          .fas {
            display: none;
          }
        }
      }
    }
  }
}

.wrapper_table {
  thead th {
    border-top: 0 !important;
  }
  tbody {
    font-size: 0.9rem;
    font-family: "is_light";
  }
}

.wrapper_list_history {
  .list_history {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #dedede;
    font-size: 0.9rem;
    .item_list_history {
      text-align: center;
      .img_cupon {
        height: 90px;
        border-radius: 5px;
      }
      .btndown {
        min-width: 120px !important;
      }
    }
  }
}

.textPointReward {
  font-size: 11px;
}
.canjea {
  color: #c91616;
  font-family: "is_medium";
}
