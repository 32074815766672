@import "../../assets/theme/vars";

.wrapper_box_stories {
  background: var(--primary_color);
  height: 100%;
  border-radius: $border_radius;
  color: $white_text;
  padding: 0.65rem 1rem;
  position: relative;
  min-height: 240px;
  .section_item_stories {
    position: relative;
    z-index: 1;

    .box_info_stories {
     
      h5{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .paragraph {
        line-height: 1.2;
        height: 54px;
        overflow: hidden;
        font-size: .95rem;
        margin-bottom: .2rem;
        min-height: 70px;
      }
      // .paragraph {
      //   line-height: 1.2;
      //   height: 50px;
      //   overflow: hidden;
      //   font-size: .95rem;
      //   margin-bottom: .2rem;
      // }
    }
    .more{
      margin-top: .75rem;
      margin-right: .5rem;
      a{
        color: #ffffff;
        font-weight: bold;
        font-size: .85rem;
        line-height: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span{
          margin-right: .35rem;
        }
      }
    }
  }
}

